<template>
  <div class="pb-4" style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; font-size: 22px; margin-bottom: 0" class="custom-title-color-black">
              Kunjungan
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row class="m-0 p-0">
        <b-col cols="6">
          <label for="tanggal">Bulan - Tahun</label>
          <date-picker
            style="width: 100%"
            id="tanggal"
            format="MMMM - YYYY"
            type="month"
            v-model="dataFilter.tanggal"
          ></date-picker>
        </b-col>
        <b-col cols="6">
          <label for="asuransi">Asuransi</label>
          <Multiselect
            id="asuransi"
            v-model="dataFilter.asuransi"
            :options="listAsuransi"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="text"
            track-by="value"
            placeholder="-- Pilih Asuransi --"
            size="sm"
          ></Multiselect>
        </b-col>
        <b-col cols="12">
          <div class="">
            <div class="mb-2 d-flex justify-content-between align-items-end">
              <h5 class="custom-title-card mt-2"><strong>Poliklinik</strong></h5>
              <b-form-checkbox
                id="select-all-1"
                name="select-all-1"
                v-model="dataFilter.allPoli"
                :value="true"
                :unchecked-value="false"
                @input="pilihSemuaPoli()"
              >
                <div style="margin-top: 2px;">Pilih Semua</div>
              </b-form-checkbox>
            </div>
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-poli"
                v-model="dataFilter.poli"
                :aria-describedby="ariaDescribedby"
                name="checkbox-poli"
                @change="dataFilter.allPoli = false"
              >
                <b-form-checkbox :value="item.ms_poli_id" v-for="(item, key) of listPoli" :key="key">
                  <div id="poli" class="" style="margin-top: 2px;">{{item.nama_poli}}</div>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </div>
        </b-col>
        <!-- <b-col cols="6">
          <div class="">
            <div class="mb-2 d-flex justify-content-between align-items-end">
              <h5 class="custom-title-card mt-2"><strong>Ruangan</strong></h5>
              <b-form-checkbox
                id="select-all-2"
                name="select-all-2"
                v-model="dataFilter.allRuang"
                :value="true"
                :unchecked-value="false"
                @change="pilihSemuaRuang()"
              >
                <div style="margin-top: 2px;">Pilih Semua</div>
              </b-form-checkbox>
            </div>
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-ruang"
                v-model="dataFilter.ruang"
                :aria-describedby="ariaDescribedby"
                name="checkbox-ruang"
                @change="dataFilter.allRuang = false"
              >
                <b-form-checkbox :value="item.ms_ruang_id" v-for="(item, key) of listRuang" :key="key">
                  <div id="ruang" class="" style="margin-top: 2px;">{{item.nama_ruang}}</div>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </div>
        </b-col> -->
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button 
              class="mr-2"
              :disabled="busy"
              @click="reset()"
              variant="danger"
            >Reset</b-button>
            <b-button
              :disabled="busy"
              @click="cari()"
              variant="success"
            >Cari</b-button>
          </div>
        </b-col>
        <b-col cols="12">
          <canvas id="myChart" ref="pendapatan_harian" style="width: 100%"></canvas>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {Chart, registerables } from 'chart.js'
Chart.register(...registerables );
export default {
  components:{
  },
  data() {
    return {
      busy: false,
      listGrafikKunjungan: [],
      myChart: null,
      dataFilter: {
        tanggal: null,
        asuransi: 10,
        allPoli: false,
        poli: [],
        allRuang: false,
        ruang: [],
      },
      listAsuransi: [
        {value: 'bpjs', text: 'BPJS'},
        {value: 'umum', text: 'Umum'},
      ],
      listPoli: [],
      listRuang: [],
      fieldsPendapatanHarian: [
        {
          key: "kd_tindakan",
          label: "Kode",
          sortDirection: "desc",
          sortable: true,
          class: "text-center",
        },
        {
          key: "nama_tindakan",
          label: "Nama Tindakan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
    }
  },
  computed: {
    
  },
  activated() {
    const vm = this
    vm.getData()
    vm.myChart = new Chart(this.$refs.pendapatan_harian, {
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Kunjungan',
            data: [],
            backgroundColor: [
              '#4dc0b5',
            ],
            borderColor: [
              '#9561e2',
            ],
            hoverOffset: 4
          },
          {
            label: 'Pemeriksaan',
            data: [],
            backgroundColor: [
              '#6574cd',
            ],
            borderColor: [
              '#9561e2',
            ],
            hoverOffset: 4
          },
        ]
      },
    })
    vm.myChart.update()
    vm.reset()
  },
  methods: {
    pilihSemuaPoli(){
      const vm = this
      vm.dataFilter.poli = []
      if(vm.dataFilter.allPoli){
        for (let i = 0; i < vm.listPoli.length; i++) {
          vm.dataFilter.poli.push(vm.listPoli[i].ms_poli_id)
        }
      }
    },
    pilihSemuaRuang(){
      const vm = this
      vm.dataFilter.ruang = []
      if(vm.dataFilter.allRuang){
        for (let i = 0; i < vm.listRuang.length; i++) {
          vm.dataFilter.ruang.push(vm.listRuang[i].ms_ruang_id)
        }
      }
    },
    async cari(){
      const vm = this
      vm.busy = false
      try {
        // console.log('vm.dataFilter.tanggal', vm.dataFilter.tanggal)
        const res = await vm.$axios.post('/kunjungan/laporan_grafik_kunjungan', {
          tahun: vm.$moment(vm.dataFilter.tanggal).format('YYYY'),
          bulan: vm.$moment(vm.dataFilter.tanggal).format('MM'),
          bulk_ms_poli_id: vm.dataFilter.poli,
          bulk_ms_ruang_id: vm.dataFilter.ruang,
        })
        console.log('kunjungan/laporan_grafik_kunjungan', res)
        if(res.data.status == 200){
          vm.listGrafikKunjungan = res.data.data
          vm.myChart.data.labels = []
          vm.myChart.data.datasets[0].data = []
          vm.myChart.data.datasets[1].data = []
          for (let i = 0; i < vm.listGrafikKunjungan.length; i++) {
            const x = vm.listGrafikKunjungan[i];
            vm.myChart.data.labels.push(vm.$moment(x.tanggal_kunjungan).format('DD'))
            vm.myChart.data.datasets[0].data.push(x.jumlah_kunjungan)
            vm.myChart.data.datasets[1].data.push(x.jumlah_pemeriksaan)
          }
          vm.myChart.update()
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async getData(){
      const vm = this
      vm.busy = false
      try {
        const listPoli = await vm.$axios.post('/ms_poli/list')
        vm.listPoli = listPoli.data.status == 200 ? listPoli.data.data : []
        const listRuang = await vm.$axios.post('/ms_ruang/list')
        vm.listRuang = listRuang.data.status == 200 ? listRuang.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      // console.log('vm.$store.state.tanggal_sesi', vm.$store.state.tanggal_sesi)
      vm.dataFilter = {
        tanggal: new Date(vm.$store.state.tanggal_sesi),
        asuransi: null,
        allPoli: false,
        poli: [],
        allRuang: false,
        ruang: [],
      }
      vm.cari()
    }
  },
}
</script>